<template>
    <div class="terms-of-service container mx-auto p-6">
        <h1 class="text-4xl font-bold text-blue-600 mb-4">Terms of Service</h1>
        <p class="mb-4"><em>Effective Date: 08/02/2025</em></p>

        <p class="mb-4">
            Welcome to MeetMyAvatar ("Service"), a messaging application provided by Ascalonic Technologies Private
            Limited ("we", "our", or "us"). By accessing or using our Service, you agree to comply with and be bound by
            these Terms of Service ("Terms"). Please read them carefully.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">1. Acceptance of Terms</h2>
        <p class="mb-4">
            By creating an account or using MeetMyAvatar, you acknowledge that you have read, understood, and agree to
            be bound by these Terms and our Privacy Policy.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">2. Description of Service</h2>
        <p class="mb-4">
            MeetMyAvatar is a chat application designed to facilitate messaging between users. To access the Service,
            you are required to register using your email address and provide basic profile details, including your name
            and profile picture URL.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">3. User Account and Registration</h2>
        <p class="mb-4">
            When you register for the Service, you agree to provide accurate, complete, and up-to-date information. You
            are responsible for maintaining the confidentiality of your account credentials and for all activities that
            occur under your account.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">4. User Conduct</h2>
        <p class="mb-4">
            You agree to use the Service only for lawful purposes and in a way that does not infringe the rights of or
            restrict or inhibit anyone else's use and enjoyment of the Service. Prohibited behavior includes, but is not
            limited to:
        </p>
        <ul class="list-disc pl-6 mb-4">
            <li>Harassing, abusing, or threatening other users.</li>
            <li>Distributing or posting harmful, offensive, or inappropriate content.</li>
            <li>Interfering with the normal operation of the Service or attempting to access it using unauthorized
                methods.</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-6 mb-2">5. Data Collection and Privacy</h2>
        <p class="mb-4">
            To provide a personalized experience and enable messaging features, we collect your email address and basic
            profile details (name and profile picture URL). For more details on how your data is handled, please review
            our Privacy Policy.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">6. Data Storage and Hosting</h2>
        <p class="mb-4">
            Your information is stored securely and hosted in Europe in accordance with the applicable data protection
            regulations, including the General Data Protection Regulation (GDPR).
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">7. Intellectual Property</h2>
        <p class="mb-4">
            All content, trademarks, and data on the Service are the property of Ascalonic Technologies Private Limited
            or its licensors. You are not permitted to use any of our intellectual property without our express written
            permission.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">8. Termination</h2>
        <p class="mb-4">
            We reserve the right to suspend or terminate your access to the Service, at our sole discretion, for conduct
            that violates these Terms or is harmful to other users or the Service.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">9. Disclaimers and Limitation of Liability</h2>
        <p class="mb-4">
            The Service is provided "as is" and without warranties of any kind, whether express or implied. To the
            maximum extent permitted by law, we disclaim all warranties and shall not be liable for any direct,
            indirect, incidental, consequential, or exemplary damages resulting from your use of the Service.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">10. Changes to Terms</h2>
        <p class="mb-4">
            We may modify these Terms from time to time. Any changes will be posted on this page, and your continued use
            of the Service after such modifications constitutes your acceptance of the new Terms.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">11. Governing Law</h2>
        <p class="mb-4">
            These Terms are governed by and construed in accordance with the laws of the jurisdiction in which Ascalonic
            Technologies Private Limited is located, without regard to its conflict of law provisions.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">12. Contact Information</h2>
        <p class="mb-4">
            If you have any questions or concerns regarding these Terms, please contact us at:
        </p>
        <p class="mb-4">
            <strong>Email:</strong> varghesedeutsch@gmail.com<br>
            <strong>Company:</strong> Ascalonic Technologies Private Limited
        </p>

        <p class="mb-4">
            By using MeetMyAvatar, you agree to be bound by these Terms of Service.
        </p>
    </div>
</template>

<script>
export default {
    name: 'TermsOfService'
}
</script>

<style scoped>
.terms-of-service {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
}

h1,
h2 {
    color: #1a202c;
}

p {
    margin-bottom: 1rem;
}

ul {
    margin-bottom: 1rem;
}
</style>