<template>
    <div class="privacy-policy container mx-auto p-6">
        <h1 class="text-4xl font-bold text-blue-600 mb-4">Privacy Policy</h1>
        <p class="mb-4"><em>Effective Date: 08/02/2025</em></p>

        <p class="mb-4">
            Welcome to MeetMyAvatar, a messaging application owned and operated by Ascalonic Technologies Private
            Limited ("we", "our", or "us"). We are committed to protecting your personal data and your privacy. This
            Privacy Policy explains how we collect, use, store, and protect your information when you use our
            application.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
        <p class="mb-2">
            <strong>Email Address:</strong> We request your email address for identification and communication purposes.
        </p>
        <p class="mb-2">
            <strong>Basic Profile Details:</strong> We also collect basic profile details, including your name and
            profile picture URL, to help personalize your experience and facilitate communication with other users.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">2. How We Use Your Information</h2>
        <p class="mb-2">
            The information we collect is used to:
        </p>
        <ul class="list-disc pl-6 mb-4">
            <li>Identify and authenticate you when you access MeetMyAvatar.</li>
            <li>Personalize your user experience within the application.</li>
            <li>Provide support and respond to any inquiries you may have.</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-6 mb-2">3. Data Storage and Hosting</h2>
        <p class="mb-4">
            Your data is stored securely and is hosted in Europe. This ensures that your personal information is
            processed and maintained in compliance with European Union data protection regulations, including the
            General Data Protection Regulation (GDPR).
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">4. Data Security</h2>
        <p class="mb-4">
            We have implemented technical and organizational measures designed to protect your data against accidental
            or unlawful destruction, loss, alteration, unauthorized disclosure, or access. However, please note that no
            method of transmission over the internet or electronic storage is completely secure.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">5. Data Sharing and Disclosure</h2>
        <p class="mb-4">
            We do not sell, trade, or otherwise transfer your personal information to outside parties, except when
            required by law or necessary to protect our rights.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">6. Your Rights</h2>
        <p class="mb-4">
            Depending on your location, you may have certain rights regarding your personal data, including:
        </p>
        <ul class="list-disc pl-6 mb-4">
            <li>The right to access the personal data we hold about you.</li>
            <li>The right to request correction or deletion of your personal data.</li>
            <li>The right to object to or restrict the processing of your data.</li>
        </ul>
        <p class="mb-4">
            If you wish to exercise any of these rights, please contact us using the details provided below.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">7. Changes to This Privacy Policy</h2>
        <p class="mb-4">
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other
            operational, legal, or regulatory reasons. We encourage you to review this policy periodically for any
            updates.
        </p>

        <h2 class="text-2xl font-semibold mt-6 mb-2">8. Contact Us</h2>
        <p class="mb-4">
            If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us
            at:
        </p>
        <p class="mb-4">
            <strong>Email:</strong> varghesedeutsch@gmail.com<br>
            <strong>Company:</strong> Ascalonic Technologies Private Limited
        </p>

        <p class="mb-4">
            By using MeetMyAvatar, you agree to the collection and use of your information as outlined in this Privacy
            Policy.
        </p>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>

<style scoped>
.privacy-policy {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
}

h1,
h2 {
    color: #1a202c;
}

p {
    margin-bottom: 1rem;
}

ul {
    margin-bottom: 1rem;
}
</style>